<template>
  <div class="content">
    <a-page-header style="padding: 0" title="On Duty Event">
      <template #extra>
        <!-- <a-button key="1" type="primary" @click="shownewModal = true">Add On Duty Task</a-button> -->
      </template>
    </a-page-header>


    <a-modal v-model:visible="showUpdateModal" title="Update Event" :maskClosable="false" :keyboard="false" centered
      @ok="shownewModal = false">

      <a-form :label-col="{ style: { width: '100px', } }">
        <a-form-item label="Confirm">
          <a-checkbox v-model:checked="tobeEdit.confirmed"></a-checkbox>
        </a-form-item>
        <a-form-item label="Remarks" name="desc">
          <a-textarea v-model:value="tobeEdit.remarks" />
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button key="submit" type="primary" @click="handleSubmitUpdate">Submit</a-button>
      </template>
    </a-modal>

    <a-table :dataSource="list" :columns="columns" :loading="listLoading" @change="tableChange"
      :scroll="{ x: 1000, y: 900 }" :pagination="{
        hideOnSinglePage: true,
        total: total,
        current: currentPage,
        defaultPageSize: limit,
      }" rowKey="id" class="ant-table-striped" :rowClassName="
  (record, index) => (record.status == 'LOCKED' ? 'table-striped' : null)
">


      <template #confirmed="{ text }">
        {{ text ? "True" : "False" }}
      </template>
      <template #targerTime="{ text }">
        {{ text ? $filters.formatTime(text) : '' }}
      </template>
      <template #Time="{ text }">
        {{ text ? $filters.formatTime(text) : '' }}
      </template>

      <template #tool="{ record }">
        <a-space>
          <a-button @click="handleUpdate(record)" v-if="record.onDutyUserId == $store.state.userInfo?.id">
            <FormOutlined />
          </a-button>
          <!-- <a-button danger @click="showDeleteConfirm(record)">
            <DeleteOutlined />
          </a-button> -->
        </a-space>
      </template>
    </a-table>
  </div>
</template>
  
<script>
// @ is an alias to /src
const columns = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "On Duty Task",
    dataIndex: "taskTitle",
  },
  {
    title: "On Duty User",
    dataIndex: "onDutyUserName",
  },
  {
    title: "Confirmed",
    dataIndex: "confirmed",
    slots: { customRender: "confirmed" },
  },
  {
    title: "remarks",
    dataIndex: "remarks",
    ellipsis: true
  },
  {
    title: "Schedule Time",
    dataIndex: "scheduleTime",
    slots: { customRender: "Time" },
  },
  {
    title: "Create Time",
    dataIndex: "createTime",
    slots: { customRender: "Time" },
  },
  {
    title: "Update Time",
    dataIndex: "updateTime",
    slots: { customRender: "Time" },
  },
  {
    title: "...",
    key: "tool",
    dataIndex: "id",
    slots: { customRender: "tool" },
  },
];
import { ExclamationCircleOutlined, DeleteOutlined, FormOutlined } from "@ant-design/icons-vue";
import { toRefs, reactive, inject, computed, createVNode } from "vue";
import { useQuery, useResult, useMutation } from "@vue/apollo-composable";
import { Modal } from "ant-design-vue";
import { Form } from 'ant-design-vue';

const useForm = Form.useForm;

export default {
  components: { DeleteOutlined, FormOutlined },
  setup(props) {
    const { $msg, gql } = inject("$");
    const v = reactive({
      limit: 20,
      total: 0,
      currentPage: 1,
      page: 1,
      shownewModal: false,
      loading: false,
      newTask: { title: '', description: '', number: 1, time: "day", oclock: 10, interval: 1 },
      showUpdateModal: false,
      tobeEdit: {},
    });

    const QL = gql`
        query ($page: Int, $limit: Int) {
          onDutyEvents(page: $page, limit: $limit) {
            items {
              id
              onDutyTaskId
              taskTitle
              onDutyUserId
              onDutyUserName
              remarks
              confirmed
              scheduleTime
              createTime
              updateTime
            }
            total
          }
        }
      `;
    const {
      result: onDutyTasks,
      onResult,
      loading: listLoading,
      onError,
      refetch,
    } = useQuery(QL, () => ({ limit: v.limit, page: v.page }), {
      fetchPolicy: "network-only",
    });

    const list = useResult(onDutyTasks, [], (data) => data.onDutyEvents.items);

    onResult(({ data }) => {
      v.total = data.onDutyEvents.total

    })

    onError((error) => {
      let { networkError, graphQLErrors } = error;
      if (networkError) {
        $msg.error("Nerwork Error");
      }
      if (graphQLErrors) {
        $msg.error(graphQLErrors[0].message);
      }
    });

    const plainOptions = computed(() => {
      let olist = list.value.filter((e) => !e.canLogin);
      return olist.map((e) => ({
        value: e.id,
        label: e.name,
      }));
    });

    const MUTATE_QL = gql`
        mutation ($title: String!, $description: String!, $interval: Int!, $oclock: Int!) {
          createOnDutyTask(title: $title, description: $description, interval: $interval, oclock: $oclock)
        }
      `;

    const {
      mutate: sendMessage,
      loading: sendMessageLoading,
      onDone: onSendMessageDone,
      onError: onSendMessageError,
    } = useMutation(MUTATE_QL);

    onSendMessageDone(() => {
      $msg.success("Added successfully");
      v.newUsers = [];
      v.shownewModal = false;
      refetch();
    });

    onSendMessageError(() => {
      $msg.error("Request error");
    });


    function tableChange(index) {
      v.page = index.current;
      v.currentPage = index.current;
      refetch()
    }

    const rulesRef = reactive({
      title: [{
        required: true,
        message: 'Please input title',
        max: 128,
      }],
      description: [{
        required: true,
        message: 'Please input description',
        max: 128,
      }],
    })

    const { resetFields, validate, validateInfos } = useForm(v.newTask, rulesRef, {
      //  onValidate: (...args) => console.log(...args),
    });


    function handleSubmit() {
      let { title, description, number, oclock, interval } = v.newTask
      // let interval = number
      sendMessage({
        title,
        description,
        interval,
        oclock,
      })
    }

    const DEL_MUTATE_QL = gql`
        mutation ($id: Int!) {
          deleteOnDutyTask(id: $id)
        }
      `;


    const {
      mutate: senDelMessage,
      loading: sendDelMessageLoading,
      onDone: onSendDelMessageDone,
      onError: onSendDelMessageError,
    } = useMutation(DEL_MUTATE_QL);

    onSendDelMessageDone(() => {
      $msg.success("Remove successfully");
      refetch();
    });

    onSendDelMessageError(() => {
      $msg.error("Remove error");
    })

    const showDeleteConfirm = (item) => {
      Modal.confirm({
        title: () => `Are you sure delete this task (${item.title})?`,
        icon: () => createVNode(ExclamationCircleOutlined),
        content: () => "Cannot recover after deletion",
        okText: () => "Yes",
        okType: "danger",
        cancelText: () => "No",
        onOk() {
          senDelMessage({ id: item.id });
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    };


    function handleUpdate(item) {

      v.tobeEdit = JSON.parse(JSON.stringify(item))
      v.showUpdateModal = true
    }


    const PUT_MUTATE_QL = gql`
        mutation ($id: Int! $confirmed: Boolean! $remarks: String!) {
          updateOnDutyEvent(id: $id, confirmed: $confirmed, remarks: $remarks)
        }
      `;

    const {
      mutate: sendUpdateMessage,
      loading: sendUpdateMessageLoading,
      onDone: onSendUpdateMessageDone,
      onError: onSendUpdateMessageError,
    } = useMutation(PUT_MUTATE_QL);

    onSendUpdateMessageDone(() => {
      $msg.success("Update successfully");
      v.showUpdateModal = false
      refetch();
    });

    function handleSubmitUpdate() {

      let { id, confirmed, remarks } = v.tobeEdit
      sendUpdateMessage({
        id, confirmed, remarks,
      })
    }



    return {
      ...toRefs(v),
      columns,
      tableChange,
      plainOptions,
      list,
      listLoading,
      sendMessage,
      sendMessageLoading,
      showDeleteConfirm,
      validateInfos,
      handleSubmit,
      handleUpdate,
      handleSubmitUpdate,
    };
  },
};
</script>
  
<style lang="scss" scoped></style>
  